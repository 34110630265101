import {Strings, names} from '../utils/interfaces'
import React from "react";
import {Alert} from "antd";

const info_en = <p style={{textAlign: 'justify', fontSize: '16px'}}>
    As a student living in the J13 Dormitory and a member of OZ J13, you can use the common rooms provided by the 
    Student Council of J13. There are a total of 4 rooms available: Gym, Fitness, Study Room, and Game Room. 
    The process is simple - at the beginning of the semester, during the announced registration period (internet, laundry rooms, gyms), 
    you register and pay a fee to the Civic Association ŠR J13, a part of which is used for the maintenance and renovation of the rooms.
    <br/>You can borrow the key at the reception after reserving the room. Without a reservation, the reception will not 
    give you the key. If you want to take advantage of this opportunity, simply click on the reservation page, reserve 
    a time and the room that suits you best. <strong>The maximum usage time for all rooms for one student is 12 hours per week. 
    You can decide for yourself how to use this time.</strong>
    <br/>Pay attention to adhering to the chosen times! In case of violation of the room usage rules or failing to 
    respect the end of the reservation period, your account may be blocked, and you will be prevented from entering 
    the common rooms for a period of one month. The usage rules are posted in each room.    
    </p>

const info_sk = <p style={{textAlign: 'justify', fontSize: '16px'}}>
    Ako študent ubytovaný v ŠD J13 a člen OZ J13 môžeš využívať spoločenské miestnosti poskytované 
    Študentskou Radou J13. Celkovo sú k dispozícii 4 miestnosti: Posilňovňa, Fitness, Študovňa a Herňa. 
    Postup je jednoduchý - začiatkom semestra sa počas vopred ohláseného zápisu (internet, práčovne, posilňovne) 
    zaregistruješ a zaplatíš poplatok do Občianskeho združenia ŠR J13, ktorého časť sa používa na údržbu a vynovovanie miestností.
    <br/>Kľúč si môžeš zapožičať na vrátnici po rezervovaní miestnosti. Bez rezervácie vrátnica kľúč nevydá. 
    Ak budeš chcieť využiť túto príležitosť, jednoducho klikneš na stránku rezervacie.intrak.sk, zarezervuješ si čas a miestnosť, 
    ktorá ti najviac vyhovuje. <strong>Maximálny čas používania všetkých miestností pre jedného študenta je 12 hodín týždenne. 
    Môžeš si sám rozhodnúť, ako tento čas využiješ.</strong>
    <br/>Dávaj pozor na dodržiavanie zvolených časov! V prípade porušenia pravidiel používania miestností 
    alebo nedodržania konca doby rezervácie môže byť tvoj účet zablokovaný a následne ti bude znemožnené vstupovať do spoločenských miestností 
    po dobu jedného mesiaca. Pravidlá používania sú vyvesené na každej jednej miestnosti.

</p>

const en : Strings = {
    login_info: 'Your login is the same as on the userpanel',
    laundry: 'Laundry',
    relax: 'Relax',
    info: 'Info',
    drying: 'Drying',
    sport: 'Sport',
    account: 'Account', 
    reserve: 'Reserve',
    drying_info: 'You can access the drying room not further than 36 hours after your last laudry reservation',
    main_info: <Alert message={info_en}
                      type={'info'}
                      
                    
    />,
    start_time: 'Start time',
    end_time: 'End time',
    logout: 'Logout',
    study_room: 'Study room',
    no_reservations: 'There are no reservations',
    reservation_ok: 'Reservation successful',
    err_reservation_pending: 'You already have a reservation',
    err_reservation_overlap: 'Reservation overlapping',
    err_reservation_start_end: 'Invalid time',
    err_reservation_future: "Invalid time",
    err_drying_only_after_laundry: "You can only reserve a drying room during 36 hours after your last laundry reservation",
    token_expired: 'Session is expired, redirect to login page',
    bugs_errors: 'Found bugs or errors? Let us know',
    err_quota: 'This reservation would exceed your quota',
    gym: 'Gym',
    fitness: 'Fitness',
    gaming: 'Gaming room',
    study: 'Study room',
    kino: 'Cinema',
    admin: 'Admin Panel',
    rule_gaming_room: (
    <div style={{ textAlign: 'justify' }}>
    <h3>Game Room Rules</h3>
    <p>
      <strong>Membership:</strong><br/>
      Only members of the Civic Association Jedlikova 13 have access to the gaming area.
    </p>
    <p>
      <strong>Entrance:</strong><br/>
      In order to enter, each visitor must reserve the room at <em>rezervacie.intrak.sk</em> 
      and then pick up the key at reception. The maximum number of visitors is 6.
    </p>
    <p>
      <strong>Opening Hours:</strong><br/>
      The gaming room is open daily from 06:00 to 22:00. 
      We ask everyone to leave the room by 22:00 at the latest.
    </p>
    <p>
      <strong>Controller Placement:</strong><br/>
      After finishing your game, please return all controllers to the charging station 
      so they are available and ready for the next players.
    </p>
    <p>
      <strong>Camera System:</strong><br/>
      Please note that there is an active camera system in the gaming area to protect property 
      and monitor compliance with the rules.
    </p>
    <p>
      <strong>No Food or Drinks:</strong><br/>
      There is a strict ban on consuming food and drinks in the gaming area to prevent any damage to equipment.
    </p>
    <p>
      <strong>Reporting Misconduct:</strong><br/>
      Any rule violations or equipment issues should be immediately reported to the Student Council Jedlikova 13 
      via email at <em>srada@j13.sk</em> or Telegram <a href="https://t.me/SR_J13_bot" target="_blank" rel="noopener noreferrer">https://t.me/SR_J13_bot</a>.
    </p>
    <p>
      <strong>Game Purchases:</strong><br/>
      The Student Council J13 has purchased a gaming pass for the console. 
      If you want to play a game that is not available, you can buy an electronic version of the game 
      and install it together with the Student Council. The game will then remain on the console's account.
    </p>
    <p>
      <strong>Equipment:</strong><br/>
      It is strictly forbidden to move any hardware, cables, the billiard table, or the table football. 
      However, you can move chairs.
    </p>
    <p>
      <strong>Theft:</strong><br/>
      Any theft will be severely punished. The offender will be excluded from the civic association and 
      forbidden from entering the common rooms. The theft will also be reported to the relevant authorities.
    </p>
    <p>
      <strong>Visiting the Room:</strong><br/>
      Only adults can visit the room on their own. Minors may visit the room only if accompanied by a responsible adult.
    </p>
    <p>
      <strong>Consequences of Rule Violations:</strong><br/>
      Violations of these rules may lead to exclusion from the civic association, a ban, 
      or denial of entry to the community rooms.
    </p>
    <p>
      Thank you for following the rules and enjoy your time gaming!
    </p>
  </div>
),
    rule_sport_room: (
  <div style={{ textAlign: 'justify' }}>
    <h3>Gym/Fitness Room Rules</h3>
    <p>
      <strong>Membership:</strong><br/>
      Entry is only allowed for students who have paid the current membership fee to the Civic Association Jedlikova 13.
    </p>
    <p>
      <strong>Capacity:</strong><br/>
      The maximum capacity in the gym/fitness room is 6 people at a time.
    </p>
    <p>
      <strong>Opening Hours:</strong><br/>
      Exercising is only allowed from 6:00 to 22:00. Entry to the gym/fitness room and exercising after 22:00 is strictly PROHIBITED!
    </p>
    <p>
      <strong>Reservations:</strong><br/>
      To enter the gym/fitness room, each visitor must reserve the room on
      <em> rezervacie.intrak.sk</em> and then pick up the key at reception.
    </p>
    <p>
      <strong>Registration:</strong><br/>
      The student is required to register at reception when picking up the keys before starting the workout, 
      and then sign in at the location where they are exercising.
    </p>
    <p>
      <strong>ISIC:</strong><br/>
      Everyone is required to have their ISIC with them and present it if checked by members of the Student Council J13 or Student Police J13. 
      Anyone without an ISIC will be excluded from the gym.
    </p>
    <p>
      <strong>Safety:</strong><br/>
      You exercise at your own risk. Individuals under 18 must be accompanied by an adult.
    </p>
    <p>
      <strong>Prohibitions:</strong><br/>
      It is strictly forbidden to move dumbbells, weights or other exercise equipment between rooms or outside the gym. 
      It is forbidden to play music through speakers if it disturbs others. 
      It is forbidden to put up any posters. 
      Leaning weights, discs, bars, or equipment against the wall is prohibited. 
      It is forbidden to drop weights on the floor or add additional weights to machines that already have designated weights.
    </p>
    <p>
      <strong>Cleaning Up:</strong><br/>
      After finishing your workout, everyone is required to return the weights to the racks, clean the bench, and maintain general order. 
      The last registered person is responsible for leaving the gym tidy, closing windows, turning off lights, 
      storing equipment properly, and locking the room.
    </p>
    <p>
      <strong>Camera System:</strong><br/>
      The gym/fitness rooms are monitored by a camera system.
    </p>
    <p>
      <strong>Inspections:</strong><br/>
      Members of the Student Council J13 and the Student Police J13 conduct regular checks in the gym/fitness room.
    </p>
    <p>
      <strong>Consequences of Violations:</strong><br/>
      In the event of any rule violation, a member of the Student Council J13 or the Student Police J13 can exclude the individual 
      from the gym/fitness room or punish them in another way, including revoking their membership in the Civic Association 
      Student Council Jedlikova 13. In that case, they lose the right to all services provided by the association. 
      The Student Council J13 may also impose punishment retroactively to the date of the rule violation.
    </p>
    <p>
      Thank you for respecting these rules and enjoy your workout!
    </p>
  </div>
)
}

const sk = {
    login_info: 'Prihlasovacie údaje sú rovnaké ako na userpanel',
    laundry: 'Práčovňa',
    relax: 'Relax/Herňa',
    info: 'Informácie',
    drying: 'Sušiareň',
    sport: 'Šport',
    account: 'Konto',
    reserve: 'Zarezervovať',
    drying_info: 'Sušiareň si môžete zarezervovať do 36 hodín po poslednej rezervácii práčovne',
    main_info: <Alert message={info_sk}
                      type={'info'}
                      
                       />,
    start_time: 'Začiatok',
    end_time: 'Koniec',
    logout: 'Odhlásiť sa',
    study_room: 'Študovňa',
    no_reservations: 'Žiadne rezervácie',
    reservation_ok: 'Rezervácia úspešná',
    err_reservation_pending: 'Už máte jednu rezerváciu',
    err_reservation_overlap: "Rezervácie sa prekrývajú",
    err_reservation_start_end: "Nesprávny čas",
    err_reservation_future: "Nesprávny čas",
    err_drying_only_after_laundry: "Sušiareň je možné rezervovať najviac 36 hodín po poslednej rezervácii práčovne",
    token_expired: 'Čas relácie vypršal, prihlaste sa este raz',
    bugs_errors: 'Našli ste chyby? Dajte nám vedieť',
    err_quota: 'Táto rezervácia by prekročila vašu kvótu',
    gym: 'Posilňovňa',
    fitness: 'Fitness',
    gaming: 'Herňa',
    study: 'Študovňa',
    kino: 'Kino',
    admin: 'Admin Panel',
    rule_gaming_room: (
      <div style={{ textAlign: 'justify' }}>
        <h3>Pravidlá Herného Priestoru</h3>
        <p>
          <strong>Členstvo:</strong><br/>
          Prístup do herného priestoru majú výhradne členovia Občianskeho združenia Jedlikova 13.
        </p>
        <p>
          <strong>Vstup:</strong><br/>
          Pre vstup je potrebné, aby každý návštevník zarezervoval miestnosť na <em>rezervacie.intrak.sk</em> a následne prevzal kľúč na vrátnici. 
          Maximálny počet návštevníkov je 6.
        </p>
        <p>
          <strong>Otváracie Hodiny:</strong><br/>
          Herná miestnosť je prístupná každý deň od 06:00 do 22:00. 
          Žiadame všetkých, aby miestnosť opustili najneskôr o 22:00.
        </p>
        <p>
          <strong>Umiestnenie Ovládačov:</strong><br/>
          Po skončení hrania je potrebné všetky herné ovládače vrátiť na nabíjaciu stanicu, aby boli dostupné a pripravené pre ďalších hráčov.
        </p>
        <p>
          <strong>Kamerový Systém:</strong><br/>
          Upozorňujeme, že v hernom priestore je aktívny kamerový systém na ochranu majetku a monitorovanie dodržiavania pravidiel.
        </p>
        <p>
          <strong>Zákaz Jedla a Nápojov:</strong><br/>
          V hernom priestore platí prísny zákaz konzumácie jedál a nápojov, aby sa zabránilo možnému poškodeniu vybavenia.
        </p>
        <p>
          <strong>Nahlasovanie Nesprávností:</strong><br/>
          Akékoľvek porušenie pravidiel alebo problémy s vybavením treba ihneď nahlásiť prostredníctvom Študentskej Rady Jedlikova 13 
          na email <em>srada@j13.sk</em> alebo cez Telegram <a href="https://t.me/SR_J13_bot" target="_blank" rel="noopener noreferrer">https://t.me/SR_J13_bot</a>.
        </p>
        <p>
          <strong>Nákup hier:</strong><br/>
          ŠR J13 zakúpila pre vás herný pass na hernú konzolu. 
          Ak si chcete zahrať hru, ktorá nie je k dispozícii, môžete si kúpiť elektronickú verziu hry, 
          ktorú následne so Študentskou radou nainštalujete. Hra potom zostane na účte hernej konzoly.
        </p>
        <p>
          <strong>Vybavenie:</strong><br/>
          Je prísny zákaz premiestňovania techniky, kabeláže, biliardového stola a stolného futbalu. 
          Stoličky si však môžete premiestňovať.
        </p>
        <p>
          <strong>Krádež:</strong><br/>
          Akékoľvek krádeže budú prísne trestané. Páchateľ bude vylúčený z občianskeho združenia a bude mu zakázaný vstup do spoločenských miestností. 
          Krádeže budú tiež hlásené príslušným orgánom.
        </p>
        <p>
          <strong>Návštevy miestnosti:</strong><br/>
          Miestnosť môžu samostatne navštevovať len plnoleté osoby. Neplnoleté osoby môžu miestnosť navštevovať 
          len v sprievode zodpovednej plnoletej osoby.
        </p>
        <p>
          <strong>Dôsledky porušenia pravidiel:</strong><br/>
          Porušenie pravidiel môže viesť k vylúčeniu z občianskeho združenia, banu alebo zákazu vstupu do spoločenských miestností.
        </p>
        <p>
          Ďakujeme za dodržiavanie pravidiel a prajeme príjemnú zábavu!
        </p>
      </div>
    ),
    rule_sport_room: (
  <div style={{ textAlign: 'justify' }}>
    <h3>Pravidlá Posilňovne/Fitness Miestnosti</h3>
    <p>
      <strong>Členstvo:</strong><br/>
      Vstup je povolený len študentom, ktorí majú zaplatené aktuálne členské Občianskeho združenia Jedlikova 13.
    </p>
    <p>
      <strong>Kapacita:</strong><br/>
      Maximálna kapacita v posilňovni/fitness miestnosti je 6 osôb naraz.
    </p>
    <p>
      <strong>Otváracie Hodiny:</strong><br/>
      Cvičenie je povolené len v čase od 6:00 do 22:00 hod. Vstup do posilňovne/fitness miestnosti a cvičenie po 22:00 je ZAKÁZANÉ!
    </p>
    <p>
      <strong>Rezervácie:</strong><br/>
      Pre vstup do posilňovne/fitness miestnosti je potrebné, aby každý návštevník zarezervoval miestnosť na
      <em> rezervacie.intrak.sk</em> a následne prevzal kľúč na vrátnici.
    </p>
    <p>
      <strong>Zapisovanie:</strong><br/>
      Študent je povinný sa zapísať na vrátnici pri prevzatí kľúčov pred začatím cvičenia a následne sa zapísať v miestnosti, kde cvičí.
    </p>
    <p>
      <strong>ISIC:</strong><br/>
      Všetci sú povinní mať pri sebe ISIC a pri kontrole členmi ŠR J13 alebo ŠP J13 ho predložiť. Osoba bez ISIC-u bude vylúčená z posilňovne.
    </p>
    <p>
      <strong>Bezpečnosť:</strong><br/>
      Cvičí sa na vlastnú zodpovednosť. Osoby mladšie ako 18 rokov musia byť v sprievode dospelej osoby.
    </p>
    <p>
      <strong>Zákazy:</strong><br/>
      Je výslovne zakázané prenášať činky, závažia a iné pomôcky na cvičenie medzi miestnosťami alebo mimo miestnosť. 
      Je zakázané púšťať hudbu cez reproduktory, ak tým rušíte ostatných. 
      Je zakázané vylepovanie akýchkoľvek plagátov. 
      Zákaz opierania závaží, kotúčov, tyčí a náradí o stenu. 
      Zákaz púšťať závažie na zem a pridávať závažie na stroje, ktoré obsahujú stanovené závažie.
    </p>
    <p>
      <strong>Upratovanie:</strong><br/>
      Po skončení cvičenia je každý povinný odložiť závažia na stojany, vyčistiť po sebe lavičku a dodržiavať všeobecný poriadok. 
      Za poriadok po opustení posilňovne zodpovedá posledná zapísaná osoba. Táto osoba je zodpovedná za zavretie okien, zhasnutie svetiel, 
      uloženie náradia na svoje miesto a zamknutie miestnosti.
    </p>
    <p>
      <strong>Kamerový Systém:</strong><br/>
      Miestnosti posilňovní/fitness sú zabezpečené kamerovým systémom.
    </p>
    <p>
      <strong>Kontroly:</strong><br/>
      V posilňovni/fitness miestnosti vykonávajú členovia Študentskej rady J13 a Študentskej polície J13 pravidelné kontroly.
    </p>
    <p>
      <strong>Dôsledky porušenia pravidiel:</strong><br/>
      Pri akomkoľvek porušení pravidiel môže člen ŠR J13 alebo ŠP J13 vylúčiť dotyčného z posilňovne/fitness miestnosti alebo ho potrestať iným spôsobom, 
      vrátane zrušenia členstva v OZ Študentská rada Jedlikova 13. Tým stráca nárok na všetky služby poskytované OZ. 
      ŠR J13 môže tiež potrestať retrospektívne k dňu porušenia pravidiel.
    </p>
    <p>
      Ďakujeme za dodržiavanie pravidiel a prajeme príjemné cvičenie!
    </p>
  </div>
)
}

const ua = {
    login_info: 'Дані для входу такі ж, як на userpanel',
    laundry: 'Пральня',
    relax: 'Ігрова',
    info: 'Інформація',
    drying: 'Сушарня',
    sport: 'Фітнес/Тренажерний зал',
    account: 'Обліковий запис',
    reserve: 'Зарезервувати',
    drying_info: 'Сушарню можна зарезервувати протягом 36 годин після останнього бронювання пральні',
    main_info: <Alert message={info_sk}
                      type={'info'}


                        />,
    start_time: 'Початок',
    end_time: 'Кінець',
    logout: 'Вийти',
    study_room: 'Навчальна',
    no_reservations: 'Немає резервацій',
    reservation_ok: 'Резервація успішна',
    err_reservation_pending: 'Ви вже маєте одну резервацію',
    err_reservation_overlap: "Колізія резервацій",
    err_reservation_start_end: "Невірний час",
    err_reservation_future: "Невірний час",
    err_drying_only_after_laundry: "Сушарню можна зарезервувати протягом 36 годин після останнього бронювання пральні",
    token_expired: 'Час сесії минув, будь ласка, увійдіть знову',
    bugs_errors: 'Знайшли помилки? Повідомте нам',
    err_quota: 'Дана резервація перевищує надану квоту'
}

const get_string = (locale:'en'|'sk'|'ua', name:names):string | JSX.Element =>{
    
    if(locale === 'en'){
        return en[name]
    }
    if(locale === 'sk'){
        return sk[name]
    }
   // if(locale === 'ua'){
     //   return ua[name]
   // }

    return name
}

export {get_string}


